import "regenerator-runtime";
import * as firebase from 'firebase/app';
import "firebase/analytics";
import { service, renderReact } from '@escs/react-escs-plugin';
import '@escs/react-escs-plugin/dist/styles.css';
import { loadTutorialDialog } from '@escs/plugin-tutorial'

console.log('[NODE_ENV]', NODE_ENV)

/* PROD */
let firebaseConfig = {
  apiKey: "AIzaSyBYHJNjR2d3f6BR5z_g4HGp5dolulYb3rc",
  authDomain: "ti-3o-prod.firebaseapp.com",
  databaseURL: "https://ti-3o-prod.firebaseio.com",
  projectId: "ti-3o-prod",
  storageBucket: "ti-3o-prod.appspot.com",
  messagingSenderId: "141225482658",
  appId: "1:141225482658:web:577d974a609a85ad15fafb",
  measurementId: "G-ZQ7X4DN34Z"
};

if (NODE_ENV === 'stage') {
  firebaseConfig = {
    apiKey: 'AIzaSyAKj1w64HYevpd6Yef0hT5zsOJBt1BUG4M',
    authDomain: "ti-3o-ad92a.firebaseapp.com",
    databaseURL: "https://ti-3o-ad92a.firebaseio.com",
    projectId: "ti-3o-ad92a",
    storageBucket: "ti-3o-ad92a.appspot.com",
    messagingSenderId: "752786460312",
    appId: "1:752786460312:web:59441c7791a118ebe94aed",
    measurementId: "G-VHMW3X7MJY"
  };
}

firebase.initializeApp(firebaseConfig);
firebase.analytics();

window.firebase = firebase;
window.escsService = service;

function getSearchParams() {
  let queryParams = {};

  if (location.search.trim().length > 0) {
    const params = new URLSearchParams(location.search);
    params.forEach((value, key) => queryParams[key] = value)
  }

  return queryParams;
}

function getCommonTrackingParams() {
  const status = service.getStatus();
  const queryParams = getSearchParams()

  return {
    gameName: status.gameName + "." + status.gameId,
    gameId: status.gameId,
    userSessionId: status.userSessionId,
    ...queryParams
  }
}

service.maybeShowNotifications();

service.on('initialized', function handleServiceInitialized(state) {
  window.dispatchEvent(new CustomEvent('escs-service-initialized'));
});

const searchParams = getSearchParams();
if (Object.keys(searchParams).length > 0) {
  service.setTrackingParams(searchParams);
}

const escsConf = NODE_ENV === 'production'
  ? {
    public_key: '9923720b-7753-46c0-9bbb-fdc4c656a0c4',
    base_url: 'https://api.escs.io',
    player_base_url: 'https://player.escs.io',
    analyticsEnv: 'prod'
  }
  : {
    public_key: '16ce5617-f0b9-4a30-96d2-9b8c20ed40f9',
    base_url: 'https://api-stage.escs.io',
    player_base_url: 'https://player-stage.escs.io',
    analyticsEnv: 'stage'
  }

service.invokeManaged(escsConf).catch(err => console.error(err));

window.logAnalyticsEvent = (event, params = {}) => {
  const commonParams = getCommonTrackingParams();
  firebase.analytics().logEvent(event, {
    ...commonParams,
    ...params
  });
}

window.addEventListener('load', () => {
  renderReact('escs-app', () => {
    const escsButtonContainer = document.getElementById('escs-button-container');
    const escsWebviewContainer = document.getElementById('escs-webview-container');
  
    window.Loader.load("ESCSButton", { visible: true, initialLeftPart: 0.8 }, escsButtonContainer);
    window.Loader.load("ESCSWebView", null, escsWebviewContainer);

    const addThisScriptElem = document.createElement('script');
    addThisScriptElem.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5eeba139725f6ac5';
    addThisScriptElem.setAttribute('async', '');
    document.body.appendChild(addThisScriptElem)

    if (!document.cookie.includes('ESCS.plugin.storage')) {
      loadTutorialDialog()
    }
  });
}, { once: true });

window.hideSocialShare = function hideSocialShare() {
  document.getElementById('addthis_inline_share_toolbox').classList.add('hidden')
}

window.showSocialShare = function showSocialShare() {
  document.getElementById('addthis_inline_share_toolbox').classList.remove('hidden')
}